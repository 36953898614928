import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import { doesNodeContainClick } from 'semantic-ui-react/dist/commonjs/lib';
import IconHeader from '@package/components/Header/IconHeader';

import { getNavigation } from '@plone/volto/actions';
import { BodyClass } from '@plone/volto/helpers';
import { Icon, LanguageSelector } from '@plone/volto/components';
import arrowRightSVG from '@plone/volto/icons/right-key.svg';
import arrowLeftSVG from '@plone/volto/icons/left-key.svg';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});

const MobileNavigation = (props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const [secondaryMenuOpened, setSecondaryMenuOpened] = React.useState(null);
  const [
    isSecondaryMobileMenuOpen,
    setIsSecondaryMobileMenuOpen,
  ] = React.useState(false);
  // const [isSearchMenuOpen, setIsSearchMenuOpen] = React.useState(false);
  const intl = useIntl();
  const menus = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (e) => {
      if (menus.current && doesNodeContainClick(menus.current, e)) return;
      closeMenus(e);
    };
    document.addEventListener('mousedown', handleClickOutside, false);

    return () =>
      document.removeEventListener('mousedown', handleClickOutside, false);
  }, []);

  function toggleMobileMenu() {
    const body = document.getElementsByTagName('body')[0];
    if (!isMobileMenuOpen) {
      body.style.position = 'fixed';
      body.style.width = '100%';
      body.style.top = '0px';
      body.style.left = '0px';
    } else {
      body.style = '';
    }
    setIsMobileMenuOpen(!isMobileMenuOpen);

    // setIsSearchMenuOpen(false);
    if (isMobileMenuOpen) {
      setIsSecondaryMobileMenuOpen(false);
      setSecondaryMenuOpened(null);
    }
  }

  // function toggleSearch(e) {
  //   e.stopPropagation();
  //   setIsSearchMenuOpen(!isSearchMenuOpen);
  //   setIsMobileMenuOpen(() => false);
  // }

  function openSecondaryMenu(index) {
    setSecondaryMenuOpened(index);
    setIsSecondaryMobileMenuOpen(true);
  }

  function closeSecondaryMenu(e) {
    e.stopPropagation();
    setIsSecondaryMobileMenuOpen(false);
    setSecondaryMenuOpened(null);
  }

  function closeMenus(e) {
    e.stopPropagation();
    setIsSecondaryMobileMenuOpen(false);
    setSecondaryMenuOpened(null);
    setIsMobileMenuOpen(false);
    const body = document.getElementsByTagName('body')[0];
    body.style = '';
    // setIsSearchMenuOpen(false);
  }

  return (
    <div className="mobile-nav mobile only tablet only" ref={menus}>
      <div className="hamburger-wrapper">
        <button
          className={cx('hamburger hamburger--collapse', {
            'is-active': isMobileMenuOpen,
          })}
          aria-label={
            isMobileMenuOpen
              ? intl.formatMessage(messages.closeMobileMenu)
              : intl.formatMessage(messages.openMobileMenu)
          }
          title={
            isMobileMenuOpen
              ? intl.formatMessage(messages.closeMobileMenu)
              : intl.formatMessage(messages.openMobileMenu)
          }
          type="button"
          onClick={toggleMobileMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>

      <CSSTransition
        in={isMobileMenuOpen}
        timeout={500}
        classNames="menu-drawer"
        unmountOnExit
      >
        <div className="menu-drawer">
          <BodyClass className="has-menu-open" />
          <ul className="sections">
            {props.items &&
              props.items.map((section, index) => (
                <li
                  key={section.url}
                  onClick={() => openSecondaryMenu(index)}
                  role="presentation"
                >
                  {section.nav_title || section.title}
                  <Icon name={arrowRightSVG} />
                  <CSSTransition
                    in={
                      isSecondaryMobileMenuOpen && secondaryMenuOpened === index
                    }
                    timeout={500}
                    classNames="menu-drawer"
                    unmountOnExit
                  >
                    <div className="menu-drawer subsection">
                      <div className="button-wrapper">
                        <button onClick={(e) => closeSecondaryMenu(e)}>
                          <Icon name={arrowLeftSVG} />
                          {section.nav_title || section.title}
                        </button>
                      </div>
                      <ul className="sections">
                        <li>
                          <Link
                            to={section.url === '' ? '/' : section.url}
                            onClick={(e) => closeMenus(e)}
                          >
                            <span>
                              <FormattedMessage
                                id="Overview"
                                defaultMessage="Overview "
                              />
                              &nbsp;
                              <span>{section.nav_title || section.title}</span>
                            </span>
                          </Link>
                        </li>

                        {section.items &&
                          section.items.map((subsection, index) => (
                            <li key={subsection.url}>
                              <Link
                                to={subsection.url}
                                onClick={(e) => closeMenus(e)}
                              >
                                {subsection.nav_title || subsection.title}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </CSSTransition>
                </li>
              ))}
          </ul>
          <ul className="mobile-tools">
            <li>
              <LanguageSelector onClickAction={closeMenus} />
            </li>
          </ul>
          <IconHeader mobile onClose={(e) => closeMenus(e)} />
        </div>
      </CSSTransition>
      {/* <CSSTransition
        in={isSearchMenuOpen}
        timeout={500}
        classNames="menu-drawer"
        unmountOnExit
      >
        <div className="menu-drawer search-menu">
          <BodyClass className="has-menu-open" />
          <SearchHero toggleSearch={toggleSearch} />
        </div>
      </CSSTransition> */}
    </div>
  );
};

export default connect(
  (state) => ({
    items: state.navigation.items,
    lang: state.intl.locale,
  }),
  { getNavigation },
)(MobileNavigation);
